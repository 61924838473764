
import { Options, Vue } from "vue-class-component";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RewardsLayout from "@/layouts/RewardsLayout.vue";
import SignUpLayout from "@/layouts/SignUpLayout.vue";

@Options({
  components: {
    DefaultLayout,
    RewardsLayout,
    SignUpLayout,
  },
  watch: {
    $route: {
      handler: (to) => {
        document.title = `MedSurvey - ${to.meta.title}` || "MedSurvey";

        const favicon = document.getElementById("favicon") as HTMLLinkElement
        let icon = "favicon.ico"

        switch (to.meta.layout) {
          case "rewards-layout":
            icon = "favicon-rewards.ico"
            break
          case "sign-up-layout":
            icon = "favicon.ico"
            break
          default:
            icon = "favicon.ico"
            break
        }

        favicon.href = process.env.BASE_URL + icon
      },
      immediate: true
    },
  },
})
export default class App extends Vue {
  get layout(): string {
    return (this.$route.meta.layout as string) || "default-layout";
  }
}
