import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import i18n from "./plugins/i18n"
import cookies from "./plugins/cookies"
import "@/plugins/axios"
import "@/plugins/vee-validate"
import "@/utils/validators"
import createVfm from "vue-final-modal"
import Maska from "maska"
import { Amplify } from "aws-amplify"
import { amplifyConfig } from "@/config"
import vClickOutside from "click-outside-vue3"
import VueTidio from "@/plugins/vue-tidio"
import "@/assets/scss/app.scss"

Amplify.configure(amplifyConfig)

const app = createApp(App)

const plugins = [
  router,
  i18n,
  cookies,
  vClickOutside,
  createVfm as any,
  Maska as any,
  VueTidio
]
plugins.forEach((plugin: any) => app.use(plugin))

app.mount("#app")
