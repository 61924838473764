
import CustomModal from "@/components/Modal/CustomModal.vue"
import { Options, Vue } from "vue-class-component"
import SurveyTermsContent from "@/components/SurveyTermsContent.vue"

@Options({
  components: {
    CustomModal,
    SurveyTermsContent
  },
  props: {
    modalName: {
      type: String,
      required: true
    }
  }
})

export default class SurveyTerms extends Vue {}
