
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    mobileLogo: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    terms: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Header extends Vue {
  back: boolean;
  mobileLogo: boolean;
  size = "";

  onBack() {
    this.$router.back();
  }

  onLogoClick() {
    this.$emit("onLogoClick");
  }
}
