
import { Options, Vue } from "vue-class-component"
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { goToPortalHomePage } from "@/api/services/InviteService"

@Options({
  components: {
    Header,
    Footer
  }
})
export default class RewardsLayout extends Vue {
  get footerSettings(): any {
    return this.$route.meta.footerSettings || {}
  }

  get layoutSettings(): any {
    return this.$route.meta.layoutSettings || {}
  }

  get headerSettings(): any {
    return this.$route.meta.headerSettings || {}
  }

  get isLogoRedirect(): boolean {
    return this.headerSettings.logoRedirect || false
  }

  get canLogoRedirect(): boolean {
    return this.headerSettings.canLogoRedirect ?? true
  }

  get id(): string {
    return this.$route.query.id as string
  }

  toWelcome(): void {
    if (!this.canLogoRedirect) return

    if (this.isLogoRedirect) {
      goToPortalHomePage()
    } else {
      this.$router.push({
        name: "welcomeV2",
        query: {
          id: this.id
        }
      })
    }
  }
}
