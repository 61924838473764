import axios from "axios";
import router from "@/router";
import { useCookies } from "vue3-cookies";

const { cookies }: any = useCookies();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 403) {
      // router.push({
      //   name: "Error",
      //   query: {
      //     type: "notValid",
      //   },
      // });
    }
    // TODO - add condition if panelist or file error
    if (status >= 500) {
      router.push({
        name: "PortalError"
      })
    }

    return Promise.reject(error);
  }
);

const axiosInstanceToken = axios.create();
const setAuthToken = (id: string): void => {
  axiosInstanceToken.defaults.headers.common["Authorization"] = cookies.isKey(
    id
  )
    ? `Bearer ${cookies.get(id)}`
    : "";
};

// setAuthToken();

export { axiosInstanceToken, setAuthToken };
