
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue";
import FooterLight from "@/components/FooterLight.vue";

@Options({
  components: {
    Header,
    FooterLight,
  },
})
export default class SignUpLayout extends Vue {
  get footerSettings(): any {
    return this.$route.meta.footerSettings || {};
  }

  get headerPreferense(): any {
    return this.$route.meta.headerPreferense || {};
  }

  onLogoClick() {
    this.$router.push({
      name: "SignUpInit",
    });
  }
}
