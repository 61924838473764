
import { Options, Vue } from "vue-class-component"
import ChatScript from "./ChatScript.vue"
import { $vfm } from "vue-final-modal"
import SurveyTermsModal from "@/components/Modal/SurveyTermsModal.vue"
import PrivacyPolicyModal from "@/components/Modal/PrivacyPolicyModal.vue"

@Options({
  components: {
    ChatScript,
    SurveyTermsModal,
    PrivacyPolicyModal
  },
  props: {
    size: {
      type: String,
      default: "sm"
    },
    empty: {
      type: Boolean,
      default: false
    },
    terms: {
      type: Boolean,
      default: false
    }
  }
})
export default class Footer extends Vue {
  size: "md" | "sm"
  empty: boolean

  privacyPolicyModalName = "PrivacyPolicyModal"
  surveyTermsModalName = "SurveyTermsModal"

  get privacyPolicyLink() {
    return this.$router.resolve({ name: "PrivacyPolicy" }).href
  }

  get surveyTermsLink() {
    return this.$router.resolve({ name: "SurveyTerms" }).href
  }

  get links() {
    return [
      {
        href: `${this.surveyTermsLink}`,
        text: "Survey Terms",
        class: "footer__item_terms",
        id: "survey-terms",
        hide: false
      },
      {
        href: `${this.privacyPolicyLink}`,
        text: "Privacy Policy",
        class: "footer__item_terms",
        id: "privacy-policy",
        hide: false
      }
    ]
  }

  onLinkClick(id: string) {
    switch (id) {
      case "survey-terms":
        $vfm.show(this.surveyTermsModalName)
        break
      case "privacy-policy":
        $vfm.show(this.privacyPolicyModalName)
        break
      default:
        break
    }
  }
}
