import { Url } from "./endpoints"
import { RedirectTypes } from "./redirect.types"

export class Redirect extends Url {
  static readonly [RedirectTypes.ACCEPT_INVITE] = (): Url => new Url("/survey")
  static readonly [RedirectTypes.PORTAL_SIGN_UP] = (): Url => new Url("/sign-up/start")
  static readonly [RedirectTypes.PORTAL_HOMEPAGE] = (): Url => new Url("")
  static readonly [RedirectTypes.PORTAL_SIGN_IN] = (): Url => new Url("/sign-in")
  static readonly [RedirectTypes.PORTAL_UNSUBSCRIBE] = (email: string): Url => new Url(`/unsubscribe?email=${email}`)
}
