const apiUrl = (uri: string): string => {
  return process.env.VUE_APP_API_INVITE_LOCATION + uri
}

const apiMedsurveyUrl = (uri: string): string => {
  return process.env.VUE_APP_API_FILE_LOCATION + uri
}

const portalUrl = (uri: string): string => {
  return process.env.VUE_APP_PORTAL_URI + uri
}

const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API_KEY

const TIDIO_API_KEY = "xdvvehf2w1ziikukcyisfhq83w6mqcqu"
const chatUrl = (): string => {
  return `//code.tidio.co/${TIDIO_API_KEY}.js`
}

const COGNITO_IDENTITY_POOL_ID =
  "us-east-1:b077e22d-4e96-4fdf-b69f-69dc46c4e5a4"
const PLACE_INDEX_NAME = "medsurvey-platform"
const amplifyConfig = {
  Auth: {
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
    region: "us-east-1"
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          [PLACE_INDEX_NAME]: {
            style: "Default style"
          }
        },
        default: PLACE_INDEX_NAME
      },
      search_indices: {
        items: [PLACE_INDEX_NAME],
        default: PLACE_INDEX_NAME
      },
      region: "us-east-1"
    }
  }
}

export {
  apiUrl,
  apiMedsurveyUrl,
  chatUrl,
  portalUrl,
  TIDIO_API_KEY,
  GOOGLE_API_KEY,
  amplifyConfig,
  COGNITO_IDENTITY_POOL_ID,
  PLACE_INDEX_NAME
}
