
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isModal: Boolean,
    default: false
  },
})

export default class PrivacyPolicyContent extends Vue {
}
