
import { Options, Vue } from "vue-class-component";
import { chatUrl } from "@/config";

@Options({
  components: {},
  props: {
    showChat: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ChatScript extends Vue {
  showChat: boolean;

  mounted() {
    if (!this.showChat) return false;

    let chatScript = document.createElement("script");
    chatScript.setAttribute("src", chatUrl());
    chatScript.setAttribute("type", "text/javascript");
    document.head.appendChild(chatScript);
    chatScript.async = true;
  }
}
