import { EndpointTypes } from "./endpoint.types"
import { apiUrl, apiMedsurveyUrl, portalUrl } from "@/config"

export class Url {
  public url: string
  public medsurveyUrl: string
  public portalUrl: string

  constructor(url: string) {
    this.url = apiUrl(url)
    this.medsurveyUrl = apiMedsurveyUrl(url)
    this.portalUrl = portalUrl(url)
  }
}

export class Endpoint extends Url {
  static readonly [EndpointTypes.CHECK_UNSUBSCRIBE] = (): Url =>
    new Url("/api/v1/unsubscribe/check")
  static readonly [EndpointTypes.SET_UNSUBSCRIBE] = (): Url =>
    new Url("/api/v1/unsubscribe")
  static readonly [EndpointTypes.UNSUBSCRIBE_DAILY_DIGEST] = (): Url =>
    new Url("/api/v1/unsubscribe/daily-digest")
  static readonly [EndpointTypes.SET_DECLINE] = (): Url =>
    new Url("/api/v1/decline")
  static readonly [EndpointTypes.CHECK_DECLINE] = (): Url =>
    new Url("/api/v1/decline/check")
  static readonly [EndpointTypes.CHECK_INVITE] = (): Url =>
    new Url("/api/v1/accept/check")
  static readonly [EndpointTypes.CHECK_BY_REF_ID] = (): Url =>
    new Url("/api/v1/accept/check-by-ref")
  static readonly [EndpointTypes.SURVEY_DISCLAIMER_EVENT] = (): Url =>
    new Url("/survey-disclaimer")
  static readonly [EndpointTypes.SURVEY_START_EVENT] = (): Url =>
    new Url("/start-screener")
  static readonly [EndpointTypes.ON_PAUSE_EVENT] = (): Url =>
    new Url("/api/v1/accept/on-pause")
  static readonly [EndpointTypes.CONTACT_ADDRESS] = (): Url =>
    new Url("/api/v1/contact/address")
  static readonly [EndpointTypes.EZ_CONTACT_ADDRESS] = ({
    id
  }: {
    id: string
  }): Url => new Url(`/api/v1/contact/address-legacy/${id}`)
  static readonly [EndpointTypes.GET_PRACTICE_LOCATION_ADDRESS] = (): Url =>
    new Url("/api/v1/contact/practice-location-address")
  static readonly [EndpointTypes.GET_GEO_LOCATION] = (): Url =>
    new Url("/api/v1/geo/location")
  static readonly [EndpointTypes.CONFIRM_ADDRESS] = (): Url =>
    new Url("/api/v1/contact/address/confirm")
  static readonly [EndpointTypes.VENDOR_FILES] = (id: string): Url =>
    new Url(`/share/api/v1/vendor/${id}/file`)
  static readonly [EndpointTypes.VENDOR_FILE_UPLOAD] = (id: string): Url =>
    new Url(`/share/api/v1/vendor/${id}/upload`)
  static readonly [EndpointTypes.VENDOR_FILE_DELETE] = (
    id: string,
    fileId: string
  ): Url => new Url(`/share/api/v1/vendor/${id}/file/${fileId}`)
  static readonly [EndpointTypes.VENDOR_FILE_DOWNLOAD] = (
    id: string,
    fileId: string
  ): Url => new Url(`/share/api/v1/vendor/${id}/file/${fileId}/download`)
  static readonly [EndpointTypes.VENDOR_FILE_DOWNLOAD_ALL] = (
    id: string
  ): Url => new Url(`/share/api/v1/vendor/${id}/download-all`)
  static readonly [EndpointTypes.VENDOR_SEND_CODE] = (id: string): Url =>
    new Url(`/share/api/v1/vendor/${id}/send-code`)
  static readonly [EndpointTypes.VENDOR_GENERATE_CODE] = (id: string): Url =>
    new Url(`/share/api/v1/vendor/${id}/generate-code`)
  static readonly [EndpointTypes.VENDOR_GENERATE_SEND_CODE] = (
    id: string
  ): Url => new Url(`/share/api/v1/vendor/${id}/code/generate/send`)
  static readonly [EndpointTypes.VENDOR_APPLY_CODE] = (id: string): Url =>
    new Url(`/share/api/v1/vendor/${id}/apply-code`)

  static readonly [EndpointTypes.CLIENT_FILES] = (id: string): Url =>
    new Url(`/share/api/v1/client/${id}/file`)
  static readonly [EndpointTypes.CLIENT_FILE_UPLOAD] = (id: string): Url =>
    new Url(`/share/api/v1/client/${id}/upload`)
  static readonly [EndpointTypes.CLIENT_FILE_DELETE] = (
    id: string,
    fileId: string
  ): Url => new Url(`/share/api/v1/client/${id}/file/${fileId}`)
  static readonly [EndpointTypes.CLIENT_FILE_DOWNLOAD] = (
    id: string,
    fileId: string
  ): Url => new Url(`/share/api/v1/client/${id}/file/${fileId}/download`)
  static readonly [EndpointTypes.CLIENT_FILE_DOWNLOAD_ALL] = (
    id: string
  ): Url => new Url(`/share/api/v1/client/${id}/download-all`)

  static readonly [EndpointTypes.CLIENT_SEND_CODE] = (id: string): Url =>
    new Url(`/share/api/v1/client/${id}/send-code`)
  static readonly [EndpointTypes.CLIENT_GENERATE_CODE] = (id: string): Url =>
    new Url(`/share/api/v1/client/${id}/generate-code`)
  static readonly [EndpointTypes.CLIENT_GENERATE_SEND_CODE] = (
    id: string
  ): Url => new Url(`/share/api/v1/client/${id}/code/generate/send`)
  static readonly [EndpointTypes.CLIENT_APPLY_CODE] = (id: string): Url =>
    new Url(`/share/api/v1/client/${id}/apply-code`)

  static readonly [EndpointTypes.GET_CONTACT] = (): Url =>
    new Url("/api/v1/contact")
  static readonly [EndpointTypes.CHANGE_CONTACT] = (): Url =>
    new Url("/api/v1/contact")
}
