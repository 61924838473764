import axios, { AxiosError } from "axios"
import { Endpoint } from "@/api/endpoints"

import { Invite } from "@/models/Invite"
import { Redirect } from "@/api/redirects"
import Common from "./Common"

export interface RedirectSimpleQuery {
  [key: string]: string
}

export default class InviteService extends Common {
  static async getById(id: string): Promise<Invite | undefined> {
    try {
      const { data } = await axios.get(Endpoint.CHECK_INVITE().url, {
        params: { id }
      })

      return Invite.create(data.data.response)
    } catch (error) {
      super.toError(error)
    }
  }

  static async getByRefId(refId: string): Promise<Invite | AxiosError> {
    try {
      const { data } = await axios.get(Endpoint.CHECK_BY_REF_ID().url, {
        params: { refId }
      })

      return Invite.create(data.data.response)
    } catch (error: any) {
      super.toError(error)

      return error.response
    }
  }

  static async accept(id: string): Promise<void> {
    try {
      window.location.href = `${Redirect.ACCEPT_INVITE().url}?id=${id}`
    } catch (error) {
      super.toError(error)
    }
  }

  static async goToPortal(payload?: RedirectSimpleQuery): Promise<void> {
    try {
      const query = new URLSearchParams()

      if (payload) {
        for (const [key, value] of Object.entries(payload)) {
          query.append(key, value)
        }
      }

      const queryString = query.toString()

      window.location.href = `${Redirect.PORTAL_SIGN_UP().portalUrl}${
        queryString.length ? `?${queryString}` : ""
      }`
    } catch (error) {
      super.toError(error)
    }
  }

  static async goToPortalHomePage(): Promise<void> {
    window.location.href = `${Redirect.PORTAL_HOMEPAGE().portalUrl}`
  }

  static async goToPortalSignIn(): Promise<void> {
    window.location.href = `${Redirect.PORTAL_SIGN_IN().portalUrl}`
  }

  static async goToPortalUnsubscribe(email: string): Promise<void> {
    window.location.href = `${
      Redirect.PORTAL_UNSUBSCRIBE(encodeURIComponent(email)).portalUrl
    }`
  }
}

export const getInvite = InviteService.getById
export const getInviteByRefId = InviteService.getByRefId
export const acceptInvite = InviteService.accept
export const goToPortal = InviteService.goToPortal
export const goToPortalHomePage = InviteService.goToPortalHomePage
export const goToPortalSignIn = InviteService.goToPortalSignIn
export const goToPortalUnsubscribe = InviteService.goToPortalUnsubscribe
