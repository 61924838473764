
import CustomModal from "@/components/Modal/CustomModal.vue"
import { Options, Vue } from "vue-class-component"
import PrivacyPolicyContent from "@/components/PrivacyPolicyContent.vue"

@Options({
  components: {
    CustomModal,
    PrivacyPolicyContent
  },
  props: {
    modalName: {
      type: String,
      required: true
    }
  }
})

export default class PrivacyPolicyModal extends Vue {

}
