import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "signup-layout" }
const _hoisted_2 = { class: "signup-layout__in" }
const _hoisted_3 = { class: "signup-layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FooterLight = _resolveComponent("FooterLight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      back: _ctx.headerPreferense.backButton,
      mobileLogo: _ctx.headerPreferense.mobileLogo,
      size: "sm",
      onOnLogoClick: _ctx.onLogoClick
    }, null, 8, ["back", "mobileLogo", "onOnLogoClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _createVNode(_component_FooterLight, {
      size: _ctx.footerSettings.size,
      angle: _ctx.footerSettings.angle
    }, null, 8, ["size", "angle"])
  ]))
}