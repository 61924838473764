
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: ""
    }
  },
})
export default class CustomModal extends Vue {
  isShown = false;
  name: string;
  className: string;
}
