/* eslint-disable no-unused-vars */
import { Plugin } from "vue"
import { TIDIO_API_KEY } from "@/config"

declare global {
  interface Window {
    readonly tidioChatApi: {
      display: (arg: unknown) => void
      open: () => void
      hide: () => void
      show: () => void
      on: (eventName: string, callback: () => void) => void
    }
  }
}

const VueTidio: Plugin = {
  install: function (app, options) {
    const defaultOptions = {
      delay: 100,
      appKey: TIDIO_API_KEY
    }

    const tidioOptions: {
      readonly appKey: string
      readonly delay: number
    } = Object.assign({}, defaultOptions, options)

    setTimeout(() => {
      const tidioScript = document.createElement("script")

      tidioScript.src = `//code.tidio.co/${tidioOptions.appKey}.js`
      document.body.appendChild(tidioScript)
    }, tidioOptions.delay)
    ;(() => {
      const onTidioChatApiReady = () => {
        app.config.globalProperties.$tidioChatApi = window.tidioChatApi

        window.tidioChatApi.hide()
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady)
      } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady)
      }
    })()
  }
}

export default VueTidio
