
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    size: {
      type: String,
      default: "md",
    },
    angle: {
      type: String,
      default: "left",
    },
  },
})
export default class Footer extends Vue {
  size: "md" | "sm";
  angle: "left" | "right";
}
