import HttpError from "@/models/Error";
import axios from "axios";

export default class Common {
  static toError(error: any) {
    if (axios.isAxiosError(error)) {
      return new HttpError(error);
    }
  }
}
