import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = {
  key: 0,
  class: "footer__left"
}
const _hoisted_3 = { class: "footer__list" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatScript = _resolveComponent("ChatScript")!
  const _component_SurveyTermsModal = _resolveComponent("SurveyTermsModal")!
  const _component_PrivacyPolicyModal = _resolveComponent("PrivacyPolicyModal")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["footer__in", [`is-${_ctx.size}`, { footer__in_terms: _ctx.terms }]])
    }, [
      (!_ctx.empty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: _normalizeClass(["footer__item", [{ 'is-hidden': item.hide }, item.class]])
                }, [
                  _createElementVNode("a", {
                    href: "#",
                    target: "_blank",
                    class: "footer__link footer__text",
                    onClick: _withModifiers(($event: any) => (_ctx.onLinkClick(item.id)), ["prevent"])
                  }, _toDisplayString(item.text), 9, _hoisted_4)
                ], 2))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_ChatScript, { showChat: true }),
    _createVNode(_component_SurveyTermsModal, { modalName: _ctx.surveyTermsModalName }, null, 8, ["modalName"]),
    _createVNode(_component_PrivacyPolicyModal, { modalName: _ctx.privacyPolicyModalName }, null, 8, ["modalName"])
  ]))
}